import React, { useContext } from 'react';
import { GlobalDataContext } from "../../context/context";
import { FiTarget } from 'react-icons/fi'
import { MdRoofing } from 'react-icons/md'
import { FaHandshake } from 'react-icons/fa'


function Values2() {
  const { rpdata } = useContext(GlobalDataContext);

  const valuesData = [
    {
      name: rpdata?.dbSlogan?.[0].slogan,
      title: rpdata?.dbValues?.[0].title,
      description: rpdata?.dbValues?.[0].description,
      icon: <FiTarget fontSize={70} className='titleColorFt' />,
      img: rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[10] : rpdata?.stock?.[0],
      logo: rpdata?.dbPrincipal?.favicon
    },
    {
      name: rpdata?.dbSlogan?.[5].slogan,
      title: rpdata?.dbValues?.[1].title,
      description: rpdata?.dbValues?.[1].description,
      icon: <MdRoofing fontSize={70} className='titleColorFt' />,
      img: rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[11] : rpdata?.stock?.[5],
      logo: rpdata?.dbPrincipal?.favicon
    },
    {
      name: rpdata?.dbSlogan?.[4].slogan,
      title: rpdata?.dbValues?.[2].title,
      description: rpdata?.dbValues?.[2].description,
      icon: <FaHandshake fontSize={70} className='titleColorFt' />,
      img: rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[4] : rpdata?.stock?.[1],
      logo: rpdata?.dbPrincipal?.favicon
    },
  ]


  return (
    <>
      <section className="bg-white">
        <div className="container px-6 py-10 mx-auto">
          <div className="grid grid-cols-1 gap-8 mt-8 md:mt-16 md:grid-cols-2 xl:grid-cols-3">
            {valuesData.map((items, index) => {
              return (
                <div className='bg-footer2 p-2 rounded'>
                  <div className="relative">
                    <img
                      className="object-cover object-center w-full h-64 rounded-lg lg:h-80"
                      src={items.img}
                      alt="_Image"
                    />
                    <div className="absolute bottom-0 flex p-3 bg-white">
                      <img
                        src={items.logo}
                        className="object-cover object-center w-10 h-10 rounded-full"
                        alt="logo"
                      />
                      <div className="mx-4">
                        <h1 className="text-sm text-black">{items.name}</h1>
                      </div>
                    </div>
                  </div>
                  <h1 className="mt-6 text-xl font-semibold text-white">
                    {items.title}
                  </h1>
                  <hr className="w-32 my-6 text-blue-500" />
                  <p className="text-sm text-white">{items.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Values2;
